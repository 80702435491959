const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import { tns } from "../../../../../node_modules/tiny-slider/src/tiny-slider"

const carousels = $$(".carousel");

if (carousels.length > 0) {
  carousels.forEach((carousel) => {
    const carouselParent = carousel.parentNode;
    const prevButton = $(".prev-slide", carouselParent);
    const nextButton = $(".next-slide", carouselParent);
    const slideTotal = $(".slide-count", carouselParent);
    const slideTracker = $(".slide-number", carouselParent);
  
    const slider = tns({
      mode: "carousel",
      container: carousel,
      items: 1,
      slideBy: "page",
      autoplay: false,
      loop: false,
      preventScrollOnTouch: "auto",
      autoHeight: true,
      controlsText: ["Prev Slide", "Next Slide"]
    });
  
    prevButton.classList.add("invisible");
  
    slideTotal.textContent = slider.getInfo().slideCount;
    slideTracker.textContent = slider.getInfo().index + 1;
  
    slider.events.on('transitionEnd', function(){
      slideTracker.textContent = slider.getInfo().index + 1;
    });
  
    carouselParent.addEventListener("click", (event) => {
      const closest = event.target.closest(".prev-slide");
      if (!closest) return;
  
      slider.goTo("prev");
      toggleButtons();
    });
  
    carouselParent.addEventListener("click", (event) => {
      const closest = event.target.closest(".next-slide");
      if (!closest) return;
  
      slider.goTo("next");
      toggleButtons();
    });
  
    function toggleButtons() {
      prevButton.classList.remove("invisible");
      nextButton.classList.remove("invisible");
  
      if (slider.getInfo().displayIndex === 1) {
        prevButton.classList.add("invisible");
      }
      if (slider.getInfo().displayIndex === slider.getInfo().slideCount) {
        nextButton.classList.add("invisible");
      }
    }
  });
}