const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const media = window.matchMedia("(max-width: 767px)");
const footerDetails = $$(".site-footer details");

const onChange = () => {
  toggleFooterDetails();
};

function toggleFooterDetails() {
  if (media.matches) {
    footerDetails.forEach((detail) => {
      detail.removeAttribute("open");
    });
  } else {
    footerDetails.forEach((detail) => {
      detail.setAttribute("open", "open");
    });
  }
}

media.addEventListener("change", onChange);
toggleFooterDetails();
