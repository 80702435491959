const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const weaponFilters = $(".weapon-filters");

function openFilters(button) {
  let fieldset = button.nextElementSibling;;
  let opened = fieldset.classList.contains("open");

  closeFilters();

  if (!opened) {
    fieldset.classList.add("open");
    button.setAttribute("aria-expanded","true");
  }
}

function closeFilters() {
  const openedFilters = $$(".filter-control fieldset.open");
  openedFilters.forEach(filter => {
    filter.classList.remove("open");
    filter.previousElementSibling.setAttribute("aria-expanded","false");
  });
}

if (weaponFilters) {
  const filterControls = $$(".filter-control fieldset", weaponFilters);
  let items = $$(".weapons-grid div[class='smugmug-image-container']");
  const filterSelectors = [];
  const loadMoreSection = $(".load-more");
  const artifactGrid = $(".weapons-grid");
  const displayAmount = 16;

  function loadMore() {
    let hiddenNotFiltered = $$("div.smugmug-image-container:not(.revealed):not(.filtered)", artifactGrid);
    let items = Array.prototype.slice.call(hiddenNotFiltered, 0, hiddenNotFiltered.length);
    revealItems(items);
  }

  function revealItems(items) {
    let slicedItems = items.slice(0, displayAmount);

    slicedItems.forEach(item => {
      item.classList.add("revealed");
    })

    if (items.length <= displayAmount) {
      loadMoreSection.classList.add("hidden");
    } else {
      loadMoreSection.classList.remove("hidden");
    }
  }

  window.addEventListener("rendered", function() {
    items = $$(".weapons-grid div[class='smugmug-image-container']");

    if (loadMoreSection && items.length > displayAmount) {
      loadMoreSection.classList.remove("hidden");
      $("button", loadMoreSection).addEventListener("click", loadMore);
    }
    filterItems();
  })

  filterControls.forEach(fieldset => {
    filterSelectors.push(`.filter-control input[name='${fieldset.name}']:checked`);
  });


  //Close filter on outer click
  document.addEventListener('click', function (event) {
    let closest = event.target.closest(".filter-control");
    if (closest) return;

    closeFilters();
  }, false);

  document.addEventListener('click', function (event) {
    let closest = event.target.closest("button.filter-button");
    if (!closest) return;

    openFilters(closest);
  }, false);
 

  let filterItems = function() {
    closeFilters();

    items.forEach(item => {
      item.classList.remove("filtered");
      item.classList.remove("revealed");
    })

    filterSelectors.forEach(selector => {
      let value = $(selector, weaponFilters).value;
      let name = $(selector, weaponFilters).name;

      if (value !== 'on') {
        items.forEach(item => {
          let content = item.getAttribute(`data-${name}`);
          if (content.indexOf(value) === -1) {
            item.classList.add("filtered");
          }
        })
      }
    })

    if (loadMoreSection) {
      let remainingItems = $$("div.smugmug-image-container:not(.filtered)", artifactGrid);
      remainingItems = Array.prototype.slice.call(remainingItems);
      revealItems(remainingItems);
    }
  }


  const filters = $$(".filter-control input[type='radio']");

  filters.forEach(input => {
    input.addEventListener('change', () => {
      let control = input.parentNode.parentNode.parentNode;
      let controlButton = $("button", control);
      let buttonSpan = $("span", controlButton)

      if (input.value === "on") {
        buttonSpan.textContent = controlButton.getAttribute("data-field");
      } else {
        buttonSpan.textContent = input.value;
      }
      filterItems();
    })
  });
}