const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

document.addEventListener("click", function(event) {
  const button = event.target.closest(`.platforma-site-header [aria-controls="menu"]`);
  if (!button) {
    return;
  }

  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = $("header[role='banner'].platforma-site-header");

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  mobileMenu.classList.toggle("menu-open");
  $("body").classList.toggle("overflow-hidden");
});

// Observes the site header, which should have `top: -1px`, and toggles
// the `stuck` class when it hits the top of the page.
new IntersectionObserver(
  ([e]) => {
    document.querySelector(`header[role="banner"]`).classList.toggle("stuck", !e.isIntersecting);
    document.querySelector(`body`).classList.toggle("scroll-started", !e.isIntersecting);
  },
  { threshold: [1] }
).observe(document.querySelector(`#scroll-anchor`));

document.addEventListener('mouseover', function (event) {
  let closest = event.target.closest(".platforma-site-header:not(.menu-open) nav > ul > .isParent, .platforma-site-header:not(.menu-open) nav > ul > .isGrandParent");
  if (!closest) return;
  openMenu(closest);
}, false);

document.addEventListener('mouseout', function (event) {
  let closest = event.target.closest(".platforma-site-header:not(.menu-open) nav > ul > .isParent, .platforma-site-header:not(.menu-open) nav > ul > .isGrandParent");
  if (!closest) return;
  closeMenu(closest);
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".platforma-site-header:not(.menu-open) a + button, .platforma-site-header:not(.menu-open) span + button");
  if (!closest) return;

  let $toggle = closest;
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  $otherNavItems.forEach(item => {
    closeMenu(item);
  })

  if ($navItem.classList.contains("open")) {
    closeMenu($navItem);
  } else {
    openMenu($navItem);
  }
}, false);

document.addEventListener('click', function (event) {
  const targets = `
    .platforma-site-header.menu-open nav .isParent > a, .platforma-site-header.menu-open nav .isParent > span,
    .platforma-site-header.menu-open nav .isGrandParent > a, .platforma-site-header.menu-open nav .isGrandParent > span,
    .platforma-site-header.menu-open nav .isParent > button,
    .platforma-site-header.menu-open nav .isGrandParent > button
  `;

  const closest = event.target.closest(targets);
  if (!closest) return;

  if(!$("ul",closest.parentNode)) {
    return;
  }

  const $navItem = closest.parentNode;
  let siblings = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  event.preventDefault();

  if ($navItem.classList.contains(`open`)) {
    closeMenu($navItem);
  } else {
    openMenu($navItem);
    siblings.forEach(sibling => {
      closeMenu(sibling);
    })
  }
}, false);

function openMenu(parent) {
  const button = $("button", parent);

  parent?.classList.add("open");
  button?.setAttribute(`aria-expanded`, `true`);
}

function closeMenu(parent) {
  const button = $("button", parent);

  parent?.classList.remove("open");
  button?.setAttribute(`aria-expanded`, `false`);
}

function throttle(fn, wait) {
  let time = Date.now();
  return function() {
    if ((time + wait - Date.now()) < 0) {
      fn();
      time = Date.now();
    }
  }
}

function scrollTo(id) {
  let $el = $(id);
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let $header = $("header[role='banner']");

  if (!$el) {
    return;
  }

  let headerOffset = 0 + $header.offsetHeight;
  if ($("body").classList.contains("samadmin")) {
    headerOffset = headerOffset + 97;
  }

  window.scroll({
    top: $el.getBoundingClientRect()["top"] + scrollTop
          - headerOffset
          - 45 /* Extra padding for visual look */,
    behavior: 'smooth'
  });
};

function setHeaderTop(header) {
  let height = header.offsetHeight;
  if ($("body").classList.contains("samadmin")) {
    let adminBarHeight = $("#SAMAdminTools").offsetHeight;
    height = height - adminBarHeight;
  }
  height = height * -1;
  header.style.top = `${height}px`;
}

let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
let header = $("header.platforma-site-header");
let body = $("body");
let resizeTimer;

let scrollCallback = function(){
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let diff = Math.abs(lastScrollTop - scrollTop);
  if (!header.classList.contains("menu-open")) {
    if (scrollTop > lastScrollTop){
      if (header.classList.contains("unfold") && diff > 10) {
        header.style.transform = `translateY(0px)`;
        header.style.opacity = `0%`;
        body.classList.remove("unfold-nav");
        setTimeout(function() {
          header.classList.remove("unfold");
        }, 300);
      }
    } else {
      if (!header.classList.contains("unfold") && diff > 10 && document.documentElement.scrollTop > 800) {
        const headerHeight = header.offsetHeight;
        header.classList.add("unfold");
        header.style.transform = `translateY(${headerHeight}px)`;
        header.style.opacity = `100%`;
        body.classList.add("unfold-nav");
        setTimeout(function() {
          body.classList.add("unfold-nav");
        }, 300);
      } else if (header.classList.contains("unfold") && document.documentElement.scrollTop <= 800) {
        header.style.transform = `translateY(0px)`;
        header.style.opacity = `0%`;
        body.classList.remove("unfold-nav");
        setTimeout(function() {
          header.classList.remove("unfold");
        }, 300);
      }
    }
  }

  if (diff > 10) {
    lastScrollTop = scrollTop<= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  }
}

window.addEventListener("scroll", throttle(scrollCallback, 25) , false);
