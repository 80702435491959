const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const requiredCheckboxGroups = $$(".required-checkbox-group");

requiredCheckboxGroups.forEach((group) => {
  const checkboxes = $$("input[type=checkbox]", group);

  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", () => {
      let isChecked = false;
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked === true) {
          isChecked = true;
        }
      }

      if (isChecked) {
        checkboxes.forEach((checkbox) => {
          checkbox.removeAttribute("required");
        });
      } else {
        checkboxes.forEach((checkbox) => {
          checkbox.setAttribute("required", true);
        });
      }
    });
  });
});
