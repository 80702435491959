import { gsap } from "../../../../node_modules/gsap/index.js";
import { ScrollTrigger } from "../../../../node_modules/gsap/ScrollTrigger.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const animationContainer = $("#animation-container");
let yDisplacement = 0;
let containerHeight = 0;

const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

let triggerOpt = {
  trigger: "#animation-container",
  start: "top 30%",
  end: "bottom bottom",
  scrub: true,
  invalidateOnRefresh: true
}

function resize() {
  let container = $("#animation-container");
  let image = $("#monument img");

  container.style.minHeight = `${image.offsetHeight*0.35}px`;
  containerHeight = container.offsetHeight;
  yDisplacement = containerHeight - (image.offsetHeight*0.35);

  if (yDisplacement < 0) {
    yDisplacement = 0;
  }
}

function throttle(func, wait = 100) {
  let timer = null;
  return function(...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}

if (animationContainer) {
  gsap.registerPlugin(ScrollTrigger);
  const throttled = throttle(resize, 500);
  window.addEventListener('resize', throttled);

  window.addEventListener('load', function () {

    resize();
    let monumentImage = $("#monument img");
    monumentImage.classList.remove("invisible");

    if (!reducedMotion.matches) {

      let tl1 = gsap.timeline({
        scrollTrigger: triggerOpt
      });

      tl1.to("#monument", {scaleX: 0.90, scaleY:0.90, y: 500, ease: "power3.out", duration: 2, force3D:false})
        .to("#monument", {scaleX: 0.5, scaleY:0.5, y: () => yDisplacement/22, ease: "linear", duration: 1, force3D:false})
        .to("#monument", {scaleX: 0.35, scaleY:0.35, y: () => yDisplacement, ease: "linear", duration: 3, force3D:false})

      // tl1.to("#monument", {scaleX: 0.60, scaleY:0.60, rotation:0.01, y: 1500, ease: "power3.out", duration: 12, force3D:false})
      //   .to("#monument", {scaleX: 0.5, scaleY:0.5, rotation:0.01, y: () => yDisplacement/2.5, ease: "linear", duration: 4, force3D:false})
      //   .to("#monument", {scaleX: 0.35, scaleY:0.35, rotation:0.01, y: () => yDisplacement, ease: "linear", duration: 6.5, force3D:false})

      const stars = gsap.timeline({
        scrollTrigger: triggerOpt
      })

      stars.to("#stars", { opacity: 0, duration:1, ease: "linear" })
      // .to("#stars", {opacity: 0, duration:5, ease: "linear" });

      // const upperCloudTL = gsap.timeline({
      //   scrollTrigger: triggerOpt
      // })

      // upperCloudTL.to("#upper-clouds", {y: 1000, x: "-10%"});

      const cloudTL = gsap.timeline({
        scrollTrigger: triggerOpt
      })

      cloudTL.to("#cloud-group", {y: 1800, x: "-25%"});

      const raysTL = gsap.timeline({
        scrollTrigger: triggerOpt
      })

      raysTL.to("#sunrays-image", {y: 1500, rotation: 35, ease: "linear"});

      gsap.utils.toArray(".gs_reveal").forEach(function(elem) {
      
        let timeline = gsap.timeline({
          scrollTrigger: {
            trigger: elem,
            start: "top 70%",
            end: "bottom 30%",
            scrub: true,
            invalidateOnRefresh: true
          }
        });
      
        timeline.to(elem, {opacity: 1, duration: 3, ease: "sine.in"})
                .to(elem, {opacity: 1, duration: 1, ease: "linear"})
                .to(elem, {opacity: 0, duration: 3, ease: "sine.out"})
      });
    }
  });
}