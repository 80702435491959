const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider"

function getImagesByAttributes(attributes, Albums) {
  const images = [];
  const title = attributes.title;
  const keyword = attributes.keyword;
  const offset = attributes.offset ?? 1;
  const limit = attributes.limit;
  let counter = 0;
  
  Albums.forEach(function (album) {
    if (album.Title == title) {
      for (var i=0,len=album.Images.length;i<len;i++) {
        image = album.Images[i];
        // As of 2014/03/08, Smugmug will convert all the commas in the keywords
        // to be semi-colons when the user updates the keywords, hence the replacements.
        var imgKeywords = image.Keywords.replace(/,\s*/g, ";")
                        .replace(/;\s*/g, ";")
                        .split(";");

        if (keyword) {
            var keywords = keyword.split(",");
            // if multiple keywords are inputted, make sure the image has all of them
            var matchingKeywords = keywords.filter(function(keyword) {
              return inArray(keyword, imgKeywords);
            });
            var containsAll = matchingKeywords.length === keywords.length;

            if (!containsAll) continue;
        }
        counter++;
        if (counter >= offset && (!limit || counter-offset < limit)) {
          images.push({image: image, album: album});
        }
      }

    } else if (album.SubCategory) {
      if ((typeof(album.SubCategory) === "string" && album.SubCategory === title) || (album.SubCategory.Name === title)) {
        counter++;
        // limit = limit || Albums.length;
        if (counter > offset && (!limit || counter-offset < limit) && album.Images) {
          // Merge the album's images to our list of images
          album.Images.forEach(function (images, idx) {
            images.push({image: i, album: album, isHidden: idx !== 0});
          });
        }
      }
    }
  });

  return images;
}

function renderImages(images, gallery, parent, lightbox, titlebox, showCaption) {
  let imgUrl, imageName, thumbUrl;
  let width = (!gallery.getAttribute('data-width'))? 500 : Number(gallery.getAttribute('data-width'));
  let group = gallery.getAttribute('data-group');
  let category = gallery.getAttribute("data-category");
  let imageList = gallery.nextElementSibling;
  
  if (gallery !== parent) {
    gallery.remove();
  }

  images.forEach(function (imageObj, idx) {
    let $container = document.createElement("div");
    let $containerInner = document.createElement("div");
    $container.classList.add("smugmug-image-container")
    $containerInner.classList.add("inner");
    if (category) {
      $container.setAttribute("data-category", category);
    }
    if (imageObj.album.Title) {
      $container.setAttribute("data-type", imageObj.album.Title);
    }
    parent.appendChild($container); // Adds to DOM so container has css applied.
    $container.appendChild($containerInner);

    var image = imageObj.image;
    var Caption = (image.Caption || '').replace(/"/g, '&quot;');
    var imageAlbum = imageObj.album;
    // Group by album name if not specified
    group = group || imageAlbum.Title.split(" ").join("");

    // Cleans the url
    if (!image.OriginalURL) return;
    imgUrl = image.OriginalURL.replace("http://", "");
    imgUrl = imgUrl.replace("https://", "");
    imageName = image.OriginalURL.split("/").pop();
    imgUrl = imgUrl.replace(imageName, "");
    imgUrl = "/content/includes/bethumbs/"+imgUrl;
    thumbUrl = imgUrl+"w"+width+"/"+imageName;
    imgUrl = imgUrl+imageName;
    // imgUrl += '?Key='+image.Key;

    if (gallery.style.display === "none" || imageObj.isHidden) {
      // We don't load the thumbnail if it is supposed to be hidden.
      thumbUrl = "";
    }
    let $img = document.createElement("img");
    $img.setAttribute("alt", Caption);
    $img.setAttribute("width", width);
    $img.setAttribute("height", 333);
    $img.setAttribute("src", thumbUrl);
    $img.setAttribute("loading", "lazy");


    if (lightbox) {
      let lightboxLink = document.createElement("a");
      let lightboxSR = document.createElement("span");
      lightboxSR.classList.add("sr-only");
      lightboxSR.textContent = "View larger image";
      if (imageList && imageList.classList.contains("image-list")) {
        const slideLink = document.createElement("a");
        slideLink.textContent = `Slide ${idx}`;
        slideLink.classList.add("hidden");
        slideLink.setAttribute("href", imgUrl);
        slideLink.setAttribute("title", Caption);
        imageList.appendChild(slideLink);
        lightboxLink.setAttribute("data-list", imageList.getAttribute("id"));
      }
      lightboxLink.setAttribute("href", imgUrl);
      lightboxLink.setAttribute("rel", "lightbox");
      lightboxLink.appendChild($img);
      lightboxLink.appendChild(lightboxSR);
      $containerInner.appendChild(lightboxLink);

    } else {
      $containerInner.appendChild($img);
      if (imageObj.isHidden) {
        $container.style.display = "none";
      }
    }

    if (showCaption) {
      const captionBox = document.createElement("div");
      const captionParagraph = document.createElement("p");
      captionParagraph.textContent = Caption;
      captionBox.classList.add("caption");
      captionBox.appendChild(captionParagraph);
      $container.appendChild(captionBox);
    }

    if (titlebox) {
      const titlebox = document.createElement("div");
      const title = document.createElement("strong");
      title.textContent = imageObj.image.Title;
      titlebox.classList.add("artDesc");
      titlebox.appendChild(title);
      $container.appendChild(titlebox);
    }
  });
}

var smugmug = function(Albums) {
  $$('.smugSlider').forEach( function(gallery, index) {

    const title = gallery.getAttribute("data-title");
    const limit = gallery.getAttribute("data-limit");
    const offset = gallery.getAttribute("data-offset");
    const keyword = gallery.getAttribute("data-keyword");
    const width = gallery.getAttribute("data-width");
    let visibleSlides = gallery.getAttribute("data-slides");
    let showCaption = false;
    let showLightbox = true;
    if (gallery.getAttribute("data-caption") === "on") {
      showCaption = true;
    }
    if (width === "1164") {
      showLightbox = false;
    }

    const albumAttributes = {
      title: title,
      limit: limit,
      offset: offset,
      keyword: keyword
    };

    const slideContainer = $(".slider", gallery);

    const imageList = document.createElement("div");
    imageList.setAttribute("id",`gallery-${index}-list`);
    imageList.classList.add("image-list");
    imageList.classList.add("hidden");
    slideContainer.insertAdjacentElement("afterend", imageList);

    let images = getImagesByAttributes(albumAttributes, Albums);

    if (images.length === 0) {
      gallery.classList.add("empty");
    }

    renderImages(images, slideContainer, slideContainer, showLightbox, false, showCaption);

    if (images.length === 0) {
      gallery.classList.add("hidden");
    }

    if (slideContainer && images.length > 0) {
      const sliderElement = slideContainer;
      const carousel = gallery;
      const counter = $(".carousel__counter", carousel);
      const current = $(".item", counter);
      const total = $(".total", counter);
      let slider;

      switch(visibleSlides) {
        case "1":
          slider = tns({
            mode: "carousel",
            container: sliderElement,
            items: 1,
            slideBy: "page",
            autoplay: false,
            controlsText: ["<span class='icon rotate-180' aria-hidden='true'>Chevron</span><span class='sr-only'>Prev Slide</span>", "<span class='icon' aria-hidden='true'>Chevron</span><span class='sr-only'>Next Slide</span>"],
            loop: false,
            preventScrollOnTouch: "auto",
            autoHeight: true
          });
          break;
        case "3":
          slider = tns({
            mode: "carousel",
            container: sliderElement,
            items: 1,
            gutter: 20,
            slideBy: "page",
            autoplay: false,
            controlsText: ["<span class='icon rotate-180' aria-hidden='true'>Chevron</span><span class='sr-only'>Prev Slide</span>", "<span class='icon' aria-hidden='true'>Chevron</span><span class='sr-only'>Next Slide</span>"],
            loop: false,
            preventScrollOnTouch: "auto",
            autoHeight: true,
            responsive: {
              500: {
                items: 2
              },
              768: {
                items: 3
              }
            }
          });
          break;
        default:
          slider = tns({
            mode: "carousel",
            container: sliderElement,
            items: 1,
            gutter: 20,
            slideBy: "page",
            autoplay: false,
            controlsText: ["<span class='icon rotate-180' aria-hidden='true'>Chevron</span><span class='sr-only'>Prev Slide</span>", "<span class='icon' aria-hidden='true'>Chevron</span><span class='sr-only'>Next Slide</span>"],
            loop: false,
            preventScrollOnTouch: "auto",
            autoHeight: true,
            responsive: {
              500: {
                items: 2
              },
              768: {
                items: 4
              }
            }
          });
      }

      const info = slider.getInfo();

      total.textContent = Math.ceil(info.slideCount/info.items);
      current.textContent = info.displayIndex;
      total.parentNode.classList.remove("invisible");

      if (total.textContent === "1") {
        counter.style.display = "none";
      }

      slider.events.on('indexChanged', function(info) {
        let division = (info.index/info.items);
        if (division === 0) {
          current.textContent = 1;
        } else {
          current.textContent = Math.ceil(division) + 1;
        }
      })

      slider.events.on('newBreakpointEnd', function(info) {
        total.textContent = Math.ceil(info.slideCount/info.items);
        let division = (info.index/info.items);
        if (division === 0) {
          current.textContent = 1;
        } else {
          current.textContent = Math.ceil(division) + 1;
        }
        if (total.textContent === "1") {
          counter.style.display = "none";
        } else {
          counter.style.display = "block";
        }
      })
    }

    // $$(".vetImages, .vetArtifacts, .artItemContainer, .vetItem").forEach(function(group) {
    //   if ($$(".smugGallery.empty", group).length === $$(".smugGallery", group).length) {
    //     // Remove the container if all SmugMug containers inside are empty.
    //     group.remove();
    //   }
    // });
  });

  const smugSingle = $$(".smugSingle");

  smugSingle.forEach( function(single) {
    const title = single.getAttribute("data-title");
    const limit = single.getAttribute("data-limit");
    const offset = single.getAttribute("data-offset");
    const keyword = single.getAttribute("data-keyword");

    const albumAttributes = {
      title: title,
      limit: limit,
      offset: offset,
      keyword: keyword
    };

    let images = getImagesByAttributes(albumAttributes, Albums);

    if (images.length === 0) {
      single.classList.add("empty");
    }

    renderImages(images, single, single, true, false, false);
  });

  const filterableGalleries = $$('.smugFilterable');

  filterableGalleries.forEach( function(gallery) {
    const title = gallery.getAttribute("data-title");
    const limit = gallery.getAttribute("data-limit");
    const offset = gallery.getAttribute("data-offset");
    const keyword = gallery.getAttribute("data-keyword");

    const albumAttributes = {
      title: title,
      limit: limit,
      offset: offset,
      keyword: keyword
    };

    let images = getImagesByAttributes(albumAttributes, Albums);

    if (images.length === 0) {
      gallery.classList.add("empty");
    }

    renderImages(images, gallery, gallery.parentNode, true, true, false);
  });

  if (filterableGalleries.length > 0) {
    const event = new Event("rendered");
    window.dispatchEvent(event);
  }
};

var inArray = function(value, array) {
    var l = array.length, found = false;
    while(l--) {
        if (value.toLowerCase() === array[l].toLowerCase()) {
            found = true;
            break;
        }
    }
    return found;
}

if ($$('.smugSlider').length > 0 || $$('.smugFilterable').length > 0 || $$('.smugSingle').length > 0) {
  async function queryAlbums() {
    let response = await fetch(`/proxy/albums`);
    let data = await response.json();
    return data;
  }

  (async () => {
    const albums = await queryAlbums();
    smugmug(albums.smugmug.result.Albums);
  })();
}