const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const alertBar = $(".alert-bar");

if (alertBar) {
  document.addEventListener('click', function (event) {
    let closest = event.target.closest("button.alert-bar__close");
    if (!closest) return;
  
    alertBar.classList.add("hidden");
  }, false);
}