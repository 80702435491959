const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const eventArticle = $("main[id='Event-Page'] article");

if (eventArticle) {
  const title = eventArticle.dataset.title;
  const urlSearch = window.location.search;
  const query = new URLSearchParams(urlSearch);
  const today = new Date();
  const dateDisplay = $(".event-date .date", eventArticle);
  const timesDisplay = $(".event-times .times", eventArticle);

  function decorateEvent(dates) {
    dates.forEach(function(event) {
      if (!event["all-day"]) {
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);
        const newTime = document.createElement('li');
        let formattedStart = startDate.toLocaleTimeString("en-US",{timezone: "America/Chicago"}).replaceAll(":00", "").replaceAll(" ","").replaceAll("AM", " a.m.").replaceAll("PM", " p.m.");
        let formattedEnd = endDate.toLocaleTimeString("en-US",{timezone: "America/Chicago"}).replaceAll(":00", "").replaceAll(" ","").replaceAll("AM", " a.m.").replaceAll("PM", " p.m.");

        newTime.textContent = `${formattedStart} - ${formattedEnd}`;
        timesDisplay.append(newTime);
      } else {
        const hoursElement = $("#scroll-anchor");
        const open = hoursElement.dataset.open;
        const close = hoursElement.dataset.close;
        const newTime = document.createElement('li');
        newTime.textContent = `${open} - ${close}`;
        timesDisplay.append(newTime);
      }
    });

    if (dates.length > 0) {
      const startDate = new Date(dates[0].start);
      if (dates[0]["all-day"]) {
        startDate.setHours(startDate.getHours() + 12);
      }
      const day = startDate.getDate();
      const year = startDate.getFullYear();

      const friendlyMonth = startDate.toLocaleString('default', { month: 'long' });
      const friendlyDate = `${friendlyMonth} ${day}, ${year}`;
      dateDisplay.textContent = friendlyDate;

      dateDisplay.closest(".event-date").classList.remove("hidden");
      timesDisplay.parentNode.classList.remove("hidden");
    }
  }

  async function checkSelected(date) {
    const month = date.getMonth();
    const year = date.getFullYear();
    const day = date.getDate();
    let response = await fetch(`/proxy/events?year=${year}&month=${month}`);
    let events = await response.json();

    const dayEvents = events.filter(function(event) {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);

      if (event["all-day"]) {
        startDate.setHours(startDate.getHours() + 12);
      }

      const selectedDateString = date.toLocaleDateString();
      const startDateString = startDate.toLocaleDateString();

      return selectedDateString === startDateString && title === event.title;
    })

    decorateEvent(dayEvents);
  }

  async function findNearestDate(date) {
    date.setHours(0,0,0,0);
    const month = date.getMonth();
    const year = date.getFullYear();
    const today = new Date();
    today.setHours(0,0,0,0);
    let foundEvents = [];

    let response = await fetch(`/proxy/events?year=${year}&month=${month}`);
    let events = await response.json();

    const dayEvents = events.filter(function(event) {
      const startDate = new Date(event.start);
      startDate.setHours(0,0,0,0);

      return startDate.getTime() >= today.getTime() && title === event.title;
    })

    if (dayEvents.length > 0) {
      const initialDate = new Date(dayEvents[0].start);
      initialDate.setHours(0,0,0,0);

      for (let i = 0; i < dayEvents.length; i++) {
        const startDate = new Date(dayEvents[i].start);
        startDate.setHours(0,0,0,0);

        if (startDate.getTime() === initialDate.getTime()) {
          foundEvents.push(dayEvents[i]);
        }
      }

      decorateEvent(foundEvents);

    } else {
      date.setMonth(date.getMonth() + 1);
      today.setMonth(today.getMonth() + 8);
      if (date.getTime() <= today.getTime()) {
        findNearestDate(date);
      }
    }
  }

  if (query.has("date")) {
    const dateString = decodeURIComponent(query.get("date"));
    const date = new Date(dateString);
    checkSelected(date);
  } else {
    const date = new Date();
    findNearestDate(date);
  }
}
