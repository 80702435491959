const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const categorySelector = $("#categoryName");
const artifactFilter = $(".filter-control-artifacts");

function openFilters(button) {
  let fieldset = button.nextElementSibling;;
  let opened = fieldset.classList.contains("open");

  closeFilters();

  if (!opened) {
    fieldset.classList.add("open");
    button.setAttribute("aria-expanded","true");
  }
}

function closeFilters() {
  const openedFilters = $$(".filter-control-artifacts fieldset.open");
  openedFilters.forEach(filter => {
    filter.classList.remove("open");
    filter.previousElementSibling.setAttribute("aria-expanded","false");
  });
}

if (artifactFilter) {
  const loadMoreSection = $(".load-more");
  let items = $$(".artifact-grid > div");
  const displayAmount = 8;

  function loadMore() {
    let hiddenNotFiltered = $$(".artifact-grid > div:not(.revealed)");
    let items = Array.prototype.slice.call(hiddenNotFiltered, 0, hiddenNotFiltered.length);
    revealItems(items);
  }

  function revealItems(items) {
    let slicedItems = items.slice(0, displayAmount);

    slicedItems.forEach(item => {
      item.classList.add("revealed");
    })

    if (items.length <= displayAmount) {
      loadMoreSection.classList.add("hidden");
    } else {
      loadMoreSection.classList.remove("hidden");
    }
  }

  if (loadMoreSection && items.length > displayAmount) {
    loadMoreSection.classList.remove("hidden");
    $("button", loadMoreSection).addEventListener("click", loadMore);
  }

  //Close filter on outer click
  document.addEventListener('click', function (event) {
    let closest = event.target.closest(".filter-control-artifacts");
    if (closest) return;

    closeFilters();
  }, false);

  document.addEventListener('click', function (event) {
    let closest = event.target.closest(".filter-control-artifacts button.filter-button");
    if (!closest) return;

    openFilters(closest);
  }, false);


  const filters = $$(".filter-control-artifacts input[type='radio']");

  filters.forEach(input => {
    input.addEventListener('change', () => {
      window.location = "default.asp?cat=" + input.value;
    })
  });

  loadMore(items);
}