const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import { tns } from "../../../../../node_modules/tiny-slider/src/tiny-slider"

const carousels = $$(".event-list");
const sam = $("body").classList.contains("samadmin") && window.location.origin.indexOf("localhost") === -1;

const serverEvents = {
  "thisMonth": [],
  "nextMonth": []
}

async function initiateSlider(carousel) {
  const carouselEvents = $$(".event-item", carousel);

  await queryServerEvents(carouselEvents);

  let slider = tns({
    mode: "carousel",
    container: carousel,
    items: 1,
    slideBy: "page",
    autoplay: false,
    controlsText: ["<span class='icon rotate-180' aria-hidden='true'>Chevron</span><span class='sr-only'>Prev Slide</span>", "<span class='icon' aria-hidden='true'>Chevron</span><span class='sr-only'>Next Slide</span>"],
    loop: false,
    preventScrollOnTouch: "auto",
    responsive: {
      768: {
        items: 3
      }
    }
  });
  carousel.classList.remove("invisible");
}

async function queryServerEvents(carouselEvents) {
  const currentDate = new Date();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  let nextMonth = month + 1;
  let nextMonthYear = year;

  if (month === 12) {
    nextMonth = 0;
    nextMonthYear = year + 1;
  }

  let response = await fetch(`/proxy/events?year=${year}&month=${month}`);
  let events = await response.json();
  events = events.filter(function(event) {
    return Date.parse(event.end) >= currentDate;
  });
  serverEvents["thisMonth"] = events;

  response = await fetch(`/proxy/events?year=${nextMonthYear}&month=${nextMonth}`);
  events = await response.json();
  serverEvents["nextMonth"] = events;

  dateTheEvents(carouselEvents, currentDate);
}

function dateTheEvents(carouselEvents, today) {
  const datedEvents = [];
  const slideContainer = carouselEvents[0].parentNode;

  carouselEvents.forEach(function(event) {
    const eventTitle = $("span[itemprop='name']", event).textContent;
    const $date = $(".date", event);
    const $month = $(".date div > span:nth-child(1)", event);
    const $day = $(".date div > span:nth-child(2)", event);
    const $srTime = document.createElement("time");

    const $link = $("a", event);

    let matchingEvent = serverEvents["thisMonth"].find(function(serverEvent) {
      return serverEvent.title === eventTitle;
    });

    if (!matchingEvent) {
      matchingEvent = serverEvents["nextMonth"].find(function(serverEvent) {
        return serverEvent.title === eventTitle;
      });
    }

    if (matchingEvent) {
      const startDate = new Date(matchingEvent.start);
      let displayDate = startDate;

      if (matchingEvent["all-day"]) {
        displayDate.setHours(displayDate.getHours() + 12);
      }

      if (Date.parse(startDate) < today) {
        displayDate = today;
      }

      $month.textContent = displayDate.toLocaleString('en-US', { month: 'short' });
      $day.textContent = displayDate.getDate();

      const datestring = `date=${encodeURIComponent(displayDate.toLocaleDateString("en-US"))}`;
      if(sam ) {
        const eventLinkHREF = $link.getAttribute("href").split("&")[0];
        $link.setAttribute("href", eventLinkHREF + `&${datestring}`);
      } else {
        const eventLinkHREF = $link.getAttribute("href").split("?")[0];
        $link.setAttribute("href", eventLinkHREF + `?${datestring}`);
      }

      $date.classList.remove("hidden");
      $srTime.setAttribute("datetime", displayDate.toISOString().split('T')[0]);
      $srTime.textContent = displayDate.toLocaleString('en-US', { month: 'long', day: 'numeric' });
      $srTime.classList.add("sr-only");

      const $eventHeadline = $("h3", event);
      if ($eventHeadline) {
        $eventHeadline.appendChild($srTime);
      }

      datedEvents.push({
        date: Date.parse(startDate),
        event: event
      });
    } else {
      event.classList.add("!hidden");
    }
  });
  const elements = document.createDocumentFragment();
  datedEvents.sort((a, b) => a.date - b.date);

  datedEvents.forEach((event) => {
    elements.appendChild(event.event.cloneNode(true));
  });

  slideContainer.innerHTML = null;
  slideContainer.appendChild(elements);
}

if (carousels.length > 0) {
  carousels.forEach((carousel) => {
    initiateSlider(carousel);
  });
}