const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const veteranAccordions = $$(".veteran-bio div.accordion");
const bioName = $("#veteran-bio-name");
const bioDate = $("#veteran-bio-date");

if (veteranAccordions.length > 0) {
  veteranAccordions.forEach(function (accordion) {
    const panel = $(".accordion-tab + div", accordion);
    const open = $(".accordion-tab .expandlink a", accordion);
    const close = $(".accordion-tab + div .closelink a", accordion);

    if (!panel || !open || !close) {
      return;
    }

    panel.setAttribute("id", "bio-panel");
    open.setAttribute("aria-expanded", "false");
    open.setAttribute("aria-controls", "bio-panel");
    close.setAttribute("aria-expanded", "true");
    close.setAttribute("aria-controls", "bio-panel");


    open.addEventListener("click", function(e) {
      e.preventDefault();
      panel.classList.add("open");
      open.classList.add("hidden");
    });

    close.addEventListener("click", function(e) {
      e.preventDefault();
      panel.classList.remove("open");
      open.classList.remove("hidden");
    });
  })
}

if (bioName) {
  const name = bioName.textContent.replace(/\u00a0/g, " ");
  const h1 = $(".page-header-image h1");
  const breadcrumb = $("ol[itemprop='breadcrumb'] li:last-child span");
  h1.textContent = name;
  breadcrumb.textContent = name;
}

if (bioDate) {
  const h1 = $(".page-header-image h1");
  const date = document.createElement("span");
  date.classList.add("date");
  date.textContent = bioDate.textContent;
  h1.appendChild(date);
}