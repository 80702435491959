const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const timelines = $$(".timeline");
const timelineGroups = $$(".timeline-group");
const mediaQuery = window.matchMedia('(max-width: 768px)');
let resizeTimer;

function adjustMargins(items) {
  items.forEach(function(item) {

    if (mediaQuery.matches) {
      item.style.marginTop = `0px`;
    } else {
      const previousSibling = item.previousElementSibling;
      if (previousSibling) {
        const height = $(".timeline-item-inner", previousSibling).offsetHeight;
        if (height > 107) {
          item.style.marginTop = `${height/2}px`;
        }
      }
    }
  });
}

function adjustTimeline(timeline) {
  let items = $$(".timeline-item:nth-child(even)", timeline);

  if (mediaQuery.matches) {
    items = $$(".timeline-item", timeline);
  }

  if (items.length > 0) {
    adjustMargins(items);
  }
}

if (timelines.length > 0) {
  timelines.forEach(function(timeline) {
    adjustTimeline(timeline);
  });
  
  window.addEventListener('resize', function(e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      timelines.forEach(function(timeline) {
        adjustTimeline(timeline);
      });
    }, 250);
  });
}

if (timelineGroups.length > 0) {
  timelineGroups.forEach(function(group) {
    const button = $("button", group);
  
    const timelines = $$(".timeline", group);
  
    if (button) {
      button.addEventListener("click", function() {
        group.classList.toggle("expand");
  
        timelines.forEach(function(timeline) {
          adjustTimeline(timeline);
        });
      });
    }

    if ($("body").classList.contains("samadmin")) {
      group.classList.add("expand");
    }
  })
}