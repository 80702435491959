const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const shareTool = $(".article-share-tool");

if (shareTool) {
  let url = document.location.href;
  let title = document.title;

  $(".article-share-tool .icon-facebook")?.setAttribute("href",`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=${encodeURIComponent(title)}`);
  $(".article-share-tool .icon-twitter")?.setAttribute("href",`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`);
  $(".article-share-tool .icon-linkedin")?.setAttribute("href",`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`);

  document.addEventListener("click", function(event) {
    const copy = event.target.closest(`.copy`);
    if (!copy) {
      return;
    }
  
    navigator.clipboard.writeText(window.location.href).then(() => {
      $("#copy-message").textContent = "Copied page URL to clipboard";
    })
  });
}