const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const hoursWidgets = $$(".hours-display");
const siteHoursWidgets = $$(".site-hours-display");

if (hoursWidgets.length > 0 || siteHoursWidgets.length > 0) {
  async function queryHours(type = "museum") {
    let response = await fetch(`/proxy/hours?type=${type}`);
    let hours = await response.json();
    return hours;
  }

  (async () => {
    if (siteHoursWidgets.length > 0) {
      const siteHours = await queryHours("site");
      const siteStart = siteHours.start;
      const siteEnd = siteHours.end;
      let formattedSiteHours = "";

      if (siteHours.error && siteHours.error.toLowerCase() === "times not defined") {
        return false;
      }

      if (siteHours.error && siteHours.error.toLowerCase() === "closed") {
        formattedSiteHours = siteHours.error;
      } else {
        let formattedSiteStart = siteStart.replaceAll(":00", "").replaceAll(" ","").replaceAll("AM", " a.m.").replaceAll("PM", " p.m.");
        let formattedSiteEnd = siteEnd.replaceAll(":00", "").replaceAll(" ","").replaceAll("AM", " a.m.").replaceAll("PM", " p.m.");
        formattedSiteHours = `${formattedSiteStart} - ${formattedSiteEnd}`;
      }

      siteHoursWidgets.forEach(function (widget) {
        widget.textContent = formattedSiteHours;
      });
    }
  })();

  (async () => {
    if (hoursWidgets.length > 0) {
      const hours = await queryHours();
      const start = hours.start;
      const end = hours.end;
      let formattedHours = "";

      if (hours.error && hours.error.toLowerCase() === "times not defined") {
        return false;
      }

      if (hours.error && hours.error.toLowerCase() === "closed") {
        formattedHours = hours.error;
      } else {
        let formattedStart = start.replaceAll(":00", "").replaceAll(" ","").replaceAll("AM", " a.m.").replaceAll("PM", " p.m.");
        let formattedEnd = end.replaceAll(":00", "").replaceAll(" ","").replaceAll("AM", " a.m.").replaceAll("PM", " p.m.");
        formattedHours = `${formattedStart} - ${formattedEnd}`;
      }

      hoursWidgets.forEach(function (widget) {
        widget.textContent = formattedHours;
      });
    }
  })();
}