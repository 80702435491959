const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const libraryNav = $(".library-scheduler .library-nav-bar");

function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1);
}

function monthDiff(date1, date2) {
  var months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return months <= 0 ? 0 : months;
}

if (libraryNav) {
  const monthDisplay = $(".selected-month", libraryNav);
  const prevButton = $(".prev-day", libraryNav);
  const nextButton = $(".next-day", libraryNav);
  const dateSelector = $("#requestedDate");
  const form = $("#selectDateForm");
  const emptyMessage = $("#emptyMessage");

  const today = new Date();
  const thisMonth = getFirstDayOfMonth(today.getFullYear(), today.getMonth());
  let currentMonth = thisMonth;
  const serverEvents = {};

  function updateDate(date) {
    currentMonth = date;
    const year = currentMonth.getFullYear();
    const monthsFromToday = monthDiff(today, currentMonth);
    const friendlyMonth = currentMonth.toLocaleString('default', { month: 'long' });
    const friendlyDate = `${friendlyMonth} ${year}`;
    monthDisplay.textContent = friendlyDate;
    queryServerEvents();

    if (monthsFromToday <= 0) {
      prevButton.disabled = true;
    } else if (monthsFromToday >= 12) {
      prevButton.disabled = false;
      nextButton.disabled = true;
    } else {
      prevButton.disabled = false;
      nextButton.disabled = false;
    }
  }

  function nextMonth() {
    let month = currentMonth.getMonth();
    let year = currentMonth.getFullYear();

    if (month === 11) {
      month = 0;
      year = year + 1;
    } else {
      month = month + 1;
    }

    const nextMonth = getFirstDayOfMonth(year, month);
    updateDate(nextMonth);
  }

  function prevMonth() {
    let month = currentMonth.getMonth();
    let year = currentMonth.getFullYear();

    if (month === 0) {
      month = 11;
      year = year - 1;
    } else {
      month = month - 1;
    }

    const prevMonth = getFirstDayOfMonth(year, month);
    updateDate(prevMonth);
  }

  function filterEvents(events) {
    dateSelector.options.length = 0;

    const placeholder = new Option("Please select one", "");
    placeholder.disabled = true;
    placeholder.selected = true;
    dateSelector.appendChild(placeholder);

    if (events.length > 0) {
      events.forEach(function(event) {
        const date = new Date(event.start);
        const dateString = date.toLocaleDateString();
        dateSelector.appendChild(new Option(dateString, dateString));
      });

      form.classList.remove("hidden");
      emptyMessage.classList.add("hidden");
    } else {
      form.classList.add("hidden");
      emptyMessage.classList.remove("hidden");
    }
  }

  async function queryServerEvents() {
    const currentDate = currentMonth;
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const key = `${year}-${month}`;

    if (!serverEvents[key]) {
      let response = await fetch(`/proxy/library?year=${year}&month=${month}`);
      let events = await response.json();
      serverEvents[key] = events;
    }

    let validEvents = serverEvents[key];

    if (month === today.getMonth() && year === today.getFullYear()) {
      validEvents = serverEvents[key].filter(function(event) {
        const endDate = new Date(event.end);
  
        return endDate.getTime() >= today.getTime();
      })
    }

    filterEvents(validEvents);
  }

  form.addEventListener("submit", function(e) {
    e.preventDefault();
    const selectedDate = dateSelector.value;
    const lightboxLauncher = $(".lightbox-trigger");
    lightboxLauncher.click();

    const lightboxForm = $("dialog form.form");
    const appointmentRadio = $("input.appointment", lightboxForm);
    const dateField = $("input[name='date']", lightboxForm);
    appointmentRadio.checked = true;
    dateField.value = selectedDate;

    return false;
  })

  nextButton.addEventListener("click", function(e){
    nextMonth();
  });

  prevButton.addEventListener("click", function(e){
    prevMonth();
  });

  updateDate(today);
}