const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

let oldmap;

let $slideHandle = $("#slideHandle");
let $map = $("#map_canvas");
let initX, firstX;

if ($slideHandle && $map) {
  let $slideWrapper = $slideHandle.parentNode;
  let startOpacity = .50;
  let handleWidth = $slideHandle.offsetWidth;
  let wrapperWidth = $slideWrapper.offsetWidth;
  let xLimit = wrapperWidth - handleWidth;
  let wrapperLeft = $slideWrapper.offsetLeft;
  $slideHandle.style.left = `${(wrapperWidth * startOpacity) - 58}px`;

  let mapCode = () => {
    let kml, map;
  
    // Maps initialize function
    function initialize() {
      // Our configuration object
      let mapConfig = {
        "googleMapsAPIKey": "AIzaSyBXZoXZZCDVPAZaCYoVvtVbhxt7l9vOpq8",
        "startLat": 29.751965945943514,
        "startLong": -95.08042054748533,
        "startZoom": 15
      }
  
      xm = -.0165;
      xs = 1.33;
      ym = -.003;
      let imageBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(29.74 + ym, -95.087 + xm),
        new google.maps.LatLng(29.74 + .022 * xs + ym, -95.087 + .033 * xs + xm)
      );
      let mapOptions = {
        center: new google.maps.LatLng(mapConfig.startLat, mapConfig.startLong),
        zoom: mapConfig.startZoom,
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID]
        },
        streetViewControl: true,
        panControl: false
      };
  
      map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);
  
      let kmlOptions = {
        preserveViewport: true
      };
  
      kml = new google.maps.KmlLayer("https://maps.google.com/maps/ms?vps=2&amp;hl=en&amp;ie=UTF8&amp;oe=UTF8&amp;authuser=0&amp;msa=0&amp;output=kml&amp;msid=216367260467981219792.0004d9efc09638bd4827d", kmlOptions);
      kml.setMap(map);
  
      /**/
      oldmap = new google.maps.GroundOverlay(
        "http://www.sanjacinto-museum.org/images/2013/battle/SJM_BattlefieldMap_v2.jpg",
        imageBounds);
  
      oldmap.setMap(map);
  
      // Listen for the dragend event
      google.maps.event.addListener(map, "dragend", () => {
        let c = map.getCenter(),
        x = c.lng(),
        y = c.lat();
        // console.log("lng:"+x, "lat:"+y);
      });
    }
  
    initialize();
  }

  function drag(e) {
    let position = initX + (e.pageX - wrapperLeft) - firstX;

    if (position > 0 && position < xLimit ) {
      $slideHandle.style.left = position + "px";
      setOpacity();
    } else if (position <= 0) {
      $slideHandle.style.left = 0 + "px";
      setOpacity();
    } else if (position >= xLimit) {
      $slideHandle.style.left = xLimit + "px";
      setOpacity();
    }
  }

  function swipe(e) {
    let contact = e.touches;
    let position = initX + (contact[0].pageX - wrapperLeft) - firstX;

    if (position > 0 && position < xLimit ) {
      $slideHandle.style.left = position + "px";
      setOpacity();
    } else if (position <= 0) {
      $slideHandle.style.left = 0 + "px";
      setOpacity();
    } else if (position >= xLimit) {
      $slideHandle.style.left = xLimit + "px";
      setOpacity();
    }
  }

  function setOpacity() {
    let opacity = $slideHandle.offsetLeft / (wrapperWidth-116);
    opacity = 1 - (opacity);
    oldmap.setOpacity(opacity);
  }

  function onKeyDown(e) {
    const target = /** @type {HTMLElement} */(e.target);
    const key = e.key;

    // Ignores if event is not coming from an accordion header.
    if (target.tagName !== "BUTTON") {
      return;
    }

    // Ignore other keys
    if (["Left", "ArrowLeft", "Right", "ArrowRight"].indexOf(key) === -1) {
      return;
    }

    let position = $slideHandle.offsetLeft;

    switch (key) {
      case "Left": // IE/Edge specific value
      case "ArrowLeft":
        position = position - 10;

        if (position > 0 && position < xLimit ) {
          $slideHandle.style.left = position + "px";
          setOpacity();
        } else if (position <= 0) {
          $slideHandle.style.left = 0 + "px";
          setOpacity();
        } else if (position >= xLimit) {
          $slideHandle.style.left = xLimit + "px";
          setOpacity();
        }
        break;
      case "Right": // IE/Edge specific value
      case "ArrowRight":
        position = position + 10;

        if (position > 0 && position < xLimit ) {
          $slideHandle.style.left = position + "px";
          setOpacity();
        } else if (position <= 0) {
          $slideHandle.style.left = 0 + "px";
          setOpacity();
        } else if (position >= xLimit) {
          $slideHandle.style.left = xLimit + "px";
          setOpacity();
        }
        break;
    }

    e.preventDefault();
  }

  $slideHandle.addEventListener("mousedown", (e) => {
    e.preventDefault();
    initX = e.target.getBoundingClientRect().left;
    firstX = e.pageX;

    window.addEventListener("mousemove", drag, false);

    window.addEventListener("mouseup", function() {
      window.removeEventListener("mousemove", drag, false);
    }, false);
  
  }, false);

  $slideHandle.addEventListener("touchstart", (e) => {
    e.preventDefault();
    initX = e.target.getBoundingClientRect().left;
    let touch = e.touches;
    firstX = touch[0].pageX;

    window.addEventListener("touchmove", swipe, false);

    window.addEventListener("touchend", function(e) {
      window.removeEventListener("touchmove", swipe, false);
    }, false);
  
  }, false);

  $slideHandle.addEventListener("keydown", onKeyDown);

  mapCode();
  oldmap.setOpacity(.50);
}