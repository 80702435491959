const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import { tns } from "../../../../../node_modules/tiny-slider/src/tiny-slider"

const monumentCarousels = $$(".carousel-monuments");

if (monumentCarousels.length > 0) {
  monumentCarousels.forEach((monumentCarousel) => {
    const carousel = $(".monuments", monumentCarousel);
    const counter = $(".monuments__counter", monumentCarousel);
    const current = $(".item", counter);
    const total = $(".total", counter);

    const slider = tns({
      mode: "carousel",
      container: carousel,
      items: 1,
      autoplay: false,
      loop: false,
      preventScrollOnTouch: "auto",
      controlsText: ["<span class='icon rotate-180' aria-hidden='true'>Chevron</span><span class='sr-only'>Prev Slide</span>", "<span class='icon' aria-hidden='true'>Chevron</span><span class='sr-only'>Next Slide</span>"],
      responsive: {
        768: {
          items: 3
        }
      }
    });
    
    const info = slider.getInfo();

    total.textContent = info.slideCount - (info.items - 1);
    current.textContent = info.displayIndex;

    if (total.textContent === "1") {
      counter.style.display = "none";
    }

    slider.events.on('indexChanged', function(info) {
      current.textContent = info.displayIndex
    })

    slider.events.on('newBreakpointEnd', function(info) {
      if (info.items === 1) {
        total.textContent = info.slideCount;
        current.textContent = info.displayIndex
      } else {
        total.textContent = info.slideCount - (info.items - 1);
        current.textContent = info.displayIndex
      }
      if (total.textContent === "1") {
        counter.style.display = "none";
      } else {
        counter.style.display = "block";
      }
    })
  })
}