const $ = (selector, parent = document) => parent.querySelector(selector);
import cssHasPseudo from "../../node_modules/@csstools/css-has-pseudo-experimental/dist/browser.mjs";

import "./dialog.js";
import "./hours.js";
import "./JSONCallback.js";
import "./google-map.js";
import "./imports.js";
import "./tables.js";

document.addEventListener("DOMContentLoaded", function() {
  $("body").classList.remove("preload");
})

cssHasPseudo(document);