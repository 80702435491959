const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const veteranFilters = $(".veteran-filters");

function openFilters(button) {
  let fieldset = button.nextElementSibling;;
  let opened = fieldset.classList.contains("open");

  closeFilters();

  if (!opened) {
    fieldset.classList.add("open");
    button.setAttribute("aria-expanded","true");
  }
}

function closeFilters() {
  const openedFilters = $$(".veteran-filter-control fieldset.open");
  openedFilters.forEach(filter => {
    filter.classList.remove("open");
    filter.previousElementSibling.setAttribute("aria-expanded","false");
  });
}

if (veteranFilters) {

  document.addEventListener('click', function (event) {
    let closest = event.target.closest("table#veteranTable tr");
    if (!closest) return;

    const link = $("a", closest);
    if (link) {
      link.click();
    }
  });


  //Close filter on outer click
  document.addEventListener('click', function (event) {
    let closest = event.target.closest(".veteran-filter-control");
    if (closest) return;

    closeFilters();
  }, false);

  document.addEventListener('click', function (event) {
    let closest = event.target.closest("button.veteran-filter-button");
    if (!closest) return;

    openFilters(closest);
  }, false);

  let updateFilter = function() {
    if (veteranTable) {
      const army = $(".veteran-filter-control input[name='army']:checked").value;
      const site = $(".veteran-filter-control input[name='site']:checked").value;
      const searchField = $("input[name='veteran-search']").value;
      let search = "";

      if (army !== "on") {
        search = army;
      }
      if (site !== "on") {
        search = search + " " + site;
      }
      search = search + " " + searchField;

      veteranTable.search(search).draw();
    }
  };

  const filters = $$(".veteran-filter-control input[type='radio']");
  const searchField = $("input[name='veteran-search']");

  filters.forEach(input => {
    input.addEventListener('change', () => {
      let control = input.parentNode.parentNode.parentNode;
      let controlButton = $("button", control);
      let buttonSpan = $("span", controlButton)
      if (input.value === "on") {
        buttonSpan.textContent = controlButton.getAttribute("data-field");
      } else {
        buttonSpan.textContent = input.value;
      }
      updateFilter();
    })
  });

  searchField.addEventListener("keyup", () => {
    updateFilter();
  });
}