const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const supportsVideo = !!document.createElement("video").canPlayType;
const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

if (supportsVideo) {
  const video = document.querySelector("video#page-header-video");

  if (video) {
    const playPauseButton = document.querySelector(".video-button");
    const mediaQuery = window.matchMedia("(min-width: 767px)");

    if (mediaQuery.matches) {
      const sources = $$("source", video);
      sources.forEach((source) => {
        source.src = source.dataset.src;
      });
      video.load();

      if (!reducedMotion.matches) {
        video.play();
        playPauseButton.classList.add("playing");
      }
    }
    // Hide the default controls
    video.controls = false;

    playPauseButton.addEventListener("click", () => {
      playPauseButton.classList.toggle("playing");
      if (playPauseButton.classList.contains("playing")) {
        video.play();
      } else {
        video.pause();
      }
    });

    video.addEventListener("ended", () => {
      playPauseButton.classList.remove("playing");
    });
  }
}