const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const currentMaps = $$("iframe[src*='google.com/maps/embed']");

if (currentMaps.length > 0) {
  // Create the script tag, set the appropriate attributes
  const script = document.createElement('script');
  script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAQeosHlmWsHAxjr5oFvQYewf7ZgrN6LR4&callback=initMap';
  script.async = true;

  const mapStyles = [
    {
      "featureType": "all",
      "elementType": "all",
      "stylers": [
        {
          "saturation": "-29"
        },
        {
          "lightness": "0"
        },
        {
          "hue": "#ff8d00"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "lightness": "0"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
        {
          "saturation": "-50"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "saturation": "5"
        }
      ]
    },
    {
      "featureType": "landscape.natural.landcover",
      "elementType": "geometry",
      "stylers": [
        {
          "saturation": "-2"
        }
      ]
    },
    {
      "featureType": "landscape.natural.terrain",
      "elementType": "geometry",
      "stylers": [
        {
          "saturation": "-17"
        }
      ]
    },
    {
      "featureType": "landscape.natural.terrain",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "saturation": "-64"
        },
        {
          "lightness": "-8"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "lightness": "-40"
        },
        {
          "saturation": "-61"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#ffffff"
        },
        {
          "weight": "1.5"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        {
          "color": "#c1bdb5"
        }
      ]
    }
  ];

  // Attach your callback function to the `window` object
  window.initMap = () => {
    const styledMapType = new google.maps.StyledMapType(mapStyles);

    currentMaps.forEach((replaceableMap) => {
      const mapAnchor = document.createElement("div");
      const monumentCoordinates = { lat: 29.7495406, lng: -95.0810547 };
      mapAnchor.classList.add("google-map");
      replaceableMap.replaceWith(mapAnchor);

      const url = new URL(replaceableMap.getAttribute("src"));
      const urlParams = new URLSearchParams(url.search);
      const mapId = urlParams.get("mid");

      map = new google.maps.Map(mapAnchor, {
        center: monumentCoordinates,
        zoom: 10,
      });

      if (mapId) {
        let mapLayer = new google.maps.KmlLayer({
          url: `https://www.google.com/maps/d/kml?mid=${mapId}`,
          preserveViewport: true
        });
        mapLayer.setMap(map);
      } else {
        new google.maps.Marker({
          position: monumentCoordinates,
          map,
          title: "San Jacinto Monument",
        });
      }

      map.mapTypes.set("styled_map", styledMapType);
      map.setMapTypeId("styled_map")
    })
  };

  // Append the 'script' element to 'head'
  document.head.appendChild(script);
}