const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
const YOUTUBE_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
const SUPPORTED_EXTENSION_REGEX = /\.(mp4|webm)$/

//#region Lightbox
const lightbox = /** @type HTMLDialogElement */ (document.getElementById("lightbox"));

function formatImage(url, caption) {
  return `<figure><img class="mx-auto" src="${url}" alt="${caption}" /><figcaption>${caption}</figcaption></figure>`;
}

if (lightbox) {
  const container = lightbox.querySelector(".modal__content");
  const closeButton = lightbox.querySelector(".close");
  const prevButton = lightbox.querySelector(".prev");
  const nextButton = lightbox.querySelector(".next");
  let images = [];
  let currentImage = 0;

  function empty(element) {
    // Just in case it's faster to check for firstChild than lastChild
    // (e.g. if the element list is implemented as a directed linked-list by the UA).
    while (element.firstChild) {
      // In general, it's significantly faster to remove the last element of a collection
      // than it is to remove the first element.
      element.removeChild(element.lastChild);
    }
  }

  document.addEventListener("click", event => {
    const target = /** @type HTMLAnchorElement */(event.target.closest("a"));
    if (target && target.matches(`a[href][rel*="lightbox"]`)) {

      const editor = target.closest(".mce-content-body");
      if (editor) {
        return;
      }

      event.preventDefault();
      const { hostname, searchParams, pathname = "", hash } = new URL(target.href);
      let videoId, content = "";

      switch (hostname) {
        case "":
        case location.hostname:
          if (pathname.indexOf("sanjacinto-museum.smugmug.com") != -1) {
            const imageUrl = target.getAttribute("href");
            const imageElement = target.querySelector("img");
            const alt = imageElement.getAttribute("alt");
            const imageList = target.getAttribute("data-list");

            if (imageList) {
              const slides = $$(`#${imageList} a`);
              if (slides.length > 0) {
                images = slides;
                prevButton.classList.remove("hidden");
                nextButton.classList.remove("hidden");
                lightbox.classList.add("dialog-carousel");

                slides.forEach(function(slide, index) {
                  const slideUrl = slide.getAttribute("href");
                  if (slideUrl === imageUrl) {
                    currentImage = index;
                  }
                });
              }
            }

            content = formatImage(imageUrl, alt);
          }

          if (hash) {
            const element = document.querySelector(hash);
            if (element) {
              content = element.innerHTML;
            }
            break;
          }

          const [, extension] = pathname.match(SUPPORTED_EXTENSION_REGEX) || [];
          if (extension) {
            content = `<figure class="media">
              <video preload="metadata" autoplay="autoplay" loop="loop" controls="controls">
                <source src="${ pathname }" type="video/${ extension }" />
              </video>
            </figure>`;
          }

          break;
        case "youtube.com":
        case "www.youtube.com":
          //videoId = searchParams.get("v");
          videoId = target.href.match(YOUTUBE_REGEX)[1]
          if (videoId) {
            content = `<div class="aspect-video">
            <iframe
              class="w-full h-full"
              width="900" height="450" frameborder="0"  allowfullscreen
              src="https://www.youtube.com/embed/${videoId}?autoplay=1&controls=1&rel=0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            </div>`;
          }
          break;
        case "youtu.be":
        case "www.youtu.be":
          videoId = pathname.substr(1);

          if (videoId) {
            content = `<div class="aspect-video">
            <iframe
              class="w-full h-full"
              width="900" height="450" frameborder="0"  allowfullscreen
              src="https://www.youtube.com/embed/${videoId}?autoplay=1&controls=1&rel=0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            </div>`;
          }
          break;
        case "vimeo.com":
        case "www.vimeo.com":
          videoId = pathname.substr(1);

          if (videoId) {
            content = `<div class="aspect-video">
              <iframe class="w-full h-full" src="https://player.vimeo.com/video/${ videoId }?autoplay=1&title=0&byline=0&portrait=0"
                width="900" height="450" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
              <div>`;
          }
          break;
        case "www.earthcam.com":
            let href = target.href;
  
            if (href) {
              content = `<div class="aspect-video">
                <iframe class="w-full h-full" src="${ href }"
                  width="900" height="450" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                <div>`;
            }
            break;
        default:
          content = `<iframe src="${ target.href }" style="width:800px; height: 90vh;"></iframe>`;
          break;
      }

      empty(container);

      container.insertAdjacentHTML("beforeend", content);

      const form = $("dialog form.form");
      const formThankyou = $("dialog .form-thankyou");

      if (form) {
        function processForm (e) {
          if (e.preventDefault) e.preventDefault();
        
          fetch(form.action, {
            method : "POST",
            body: new FormData(form),
          }).then(
              response => response.text()
          ).then(
              message => {
                form.classList.add("hidden");
                formThankyou.classList.remove("hidden");
              }
          );
        }

        form.addEventListener("submit", processForm);
      }

      lightbox.showModal();
    }
  });

  // Handle click on the backdrop to close the modal.
  lightbox.addEventListener("click", event => {
    // Clicking on the backdrop.
    if (event.target == lightbox || event.target.closest(".close") == closeButton) {
      lightbox.close();
    }
  });

  lightbox.addEventListener("click", event => {
    // Clicking on prev button.
    if (event.target.closest(".prev") == prevButton) {
      currentImage = currentImage - 1;
      if (currentImage < 0) {
        currentImage = images.length - 1;
      }

      const prevImage = images[currentImage];
      const prevUrl = prevImage.getAttribute("href");
      const prevTitle = prevImage.getAttribute("title");
      const content = formatImage(prevUrl, prevTitle);

      empty(container);
      container.insertAdjacentHTML("beforeend", content);
    }
  });

  lightbox.addEventListener("click", event => {
    // Clicking on next button.
    if (event.target.closest(".next") == nextButton) {
      currentImage = currentImage + 1;
      if (currentImage >= images.length) {
        currentImage = 0;
      }

      const nextImage = images[currentImage];
      const nextUrl = nextImage.getAttribute("href");
      const nextTitle = nextImage.getAttribute("title");
      const content = formatImage(nextUrl, nextTitle);

      empty(container);
      container.insertAdjacentHTML("beforeend", content);
    }
  });

  lightbox.addEventListener("close", event => {
    prevButton.classList.add("hidden");
    nextButton.classList.add("hidden");
    lightbox.classList.remove("dialog-carousel");
    currentImage = 0;
    images = [];
    empty(container);
  });
}