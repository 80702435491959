const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const tables = $$(".content table.member-table");

if (tables.length > 0) {
  tables.forEach((table) => {
    const wrapper = document.createElement("div");
    wrapper.classList.add("member-table-scroll");
    table.parentNode.insertBefore(wrapper, table);
    wrapper.appendChild(table);
  });
}
