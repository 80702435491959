import { gsap } from "../../../../node_modules/gsap/index.js";
import { ScrollToPlugin } from "../../../../node_modules/gsap/ScrollToPlugin";
import { ScrollTrigger } from "../../../../node_modules/gsap/ScrollTrigger.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const smokeAnimations = $$(".smoke-effect");
const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

if (smokeAnimations.length > 0 && !reducedMotion.matches) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);

  smokeAnimations.forEach(function(animation) {
    let tl = gsap.timeline({defaults: {duration: 1, ease: "none"}, paused: true})
      .to($(".image-overlay", animation), {x: "-49%", duration: 1});

    const st = ScrollTrigger.create({
      trigger: $(".image-overlay", animation),
      scrub: true,
      start: "top bottom",
      end: "bottom top",
      onUpdate: ({progress, direction}) => {
        if (direction === 1) {
          tl.progress(progress);
        }
      }
    })
  });
}
